import React, { useState, useEffect, useMemo } from 'react';
import './App.css';
import logo from './img/logo.svg';
import livedot from './img/livedot.svg';
import loadingimg from './img/loading.svg';

import arrow from './img/arrow.svg';

import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts';
import StarRating from './components/StarRating';
import TrailModal from './components/TrailModal';

import {formatNumber, formatDate} from './components/functions.js';


import FeedbackCard from './components/FeedbackCard.js';
import FeedbackModal from './components/FeedbackModal.js';


ApexCharts.global = {
  fontFamily: 'inherit'
};

function Dashboard() {

  const testdata = {
    "region": {
        "regionid": 0,
        "name": "wunderweg",
        "slug": "wunderweg"
    },
    "newusers": [],
    "totalstations": "0",
    "trails": [],
    "feedback": []
}

  const [data, setData] = useState(testdata);
  const [loading, setLoading] = useState(true);

  const [trailopen, setTrailopen] = useState(null);

  const [feedbackopen, setFeedbackopen] = useState(false);


const trailpiechart = useMemo(() => {
  const series = data?.trails?.map(obj => parseInt(obj.started));
  const labels = data?.trails?.map(trail => trail.title);
  return {
    series,
    options: {
      labels,
      legend: {
        position: 'right',
        horizontalAlign: 'center',
        fontSize: 16
      },
      title: {
        text: 'Besuch der Wege',
        align: 'left',
      },
    }
  };
}, [data]);

/*
const languagedata = useMemo(() => {
  const languagelabels = {
    en: "🇬🇧 Englisch",
    de: "🇩🇪 Deutsch",
    it: "🇮🇹 Italien"
  }
  // Step 1: Aggregate the data
  const languageCounts = data?.newusers?.reduce((acc, obj) => {
    const { language } = obj;
    if (acc[language]) {
      acc[language] += 1;
    } else {
      acc[language] = 1;
    }
    return acc;
  }, {});

  // Step 2: Prepare series and labels
  const series = Object.values(languageCounts);
  const labels = Object.keys(languageCounts).map(lang => languagelabels[lang] || lang);

  return {
    series,
    options: {
      labels,
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        fontSize: 16
      },
      title: {
        text: 'Sprache',
        align: 'left',
      },
    }
  };
}, [data]);

*/

// langdatanew

const languagedatanew = useMemo(() => {
  const languagelabels = {
    en: "🇬🇧 Englisch",
    de: "🇩🇪 Deutsch",
    it: "🇮🇹 Italien",
  };

  // Use the backend-provided data directly
  const series = data?.languages_chart?.map(item => item.count) || [];
  const labels = data?.languages_chart?.map(item => languagelabels[item.language] || item.language) || [];

  return {
    series,
    options: {
      labels,
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        fontSize: 16,
      },
      title: {
        text: 'Sprache',
        align: 'left',
      },
    },
  };
}, [data.languages_chart]);

const visittimesdata = useMemo(() => {
  const numericData = data?.visits?.map(Number) || [];
  const maxIndex = numericData.reduce((maxIdx, currentValue, currentIndex, array) => {
    return currentValue > array[maxIdx] ? currentIndex : maxIdx;
}, 0);

  return {
    maxindex: maxIndex,
    series: [{
      name: 'Stationen angesehen',
      data: data?.visits
    }],
    options: {
      plotOptions: {
        bar: {
          columnWidth: '80%',
          endingShape: 'flat',
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: Array.from({ length: 24 }, (_, i) => i + ":00 - " + (i+1) + ":00"), // Hours from 0 to 23,
        labels: {
          show: false,
          hideOverlappingLabels: true,
          rotate: 0
        }
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      title: {
        text: 'Tageszeiten',
        align: 'left'
      }
    }
  };
}, [data]);

/*
const lineChartOptions = useMemo(() => {
  const dateCounts = data?.newusers?.reduce((acc, user) => {
    const date = user.timestamp.split(' ')[0];
    const timestamp = new Date(date).getTime();
    if (!acc[timestamp]) {
      acc[timestamp] = 0;
    }
    acc[timestamp]++;
    return acc;
  }, {});


  // Create an array of dates spanning the entire range of your data
  const startDate = new Date(data.region.startdate || "2024-01-01"); // adjust the start date as needed
  const endDate = new Date();
  const dates = [];
  for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
    const timestamp = d.getTime();
    dates.push(timestamp);
  }

  // Ensure each date has a count, even if it's zero
  const counts = dates?.map(date => ({ x: date, y: dateCounts[date] || 0 }));

  return {
    chart: {
      type: 'line',
      zoom: {
        enabled: true
      }
    },
    xaxis: {
      type: 'datetime',
    },
    series: [{
      name: 'Familien',
      data: counts
    }],
    title: {
      text: 'Neue Familien (App Downloads)',
      align: 'left'
    },
    stroke: {
      curve: 'smooth'
    },
    markers: {
      size: 0
    },
    legend: {
      position: 'top',
      fontSize: '16px'
    }
  };
}, [data.newusers]);

*/

// NEW

const lineChartOptionsnew = useMemo(() => {
  return {
    chart: {
      type: 'line',
      zoom: {
        enabled: true,
      },
    },
    xaxis: {
      type: 'datetime', // Use datetime for proper x-axis scaling
    },
    series: [{
      name: 'Familien',
      data: data.newusers_chart || [], // Use pre-aggregated data from the backend
    }],
    title: {
      text: 'Neue Familien (App Downloads)',
      align: 'left',
    },
    stroke: {
      curve: 'smooth',
    },
    markers: {
      size: 0, // No markers for better performance with large datasets
    },
    legend: {
      position: 'top',
      fontSize: '16px',
    },
  };
}, [data.newusers_chart]);



function logout() {
  localStorage.removeItem("token");
  window.location.reload();
}

useEffect(() => {
  // Define an async function to fetch data
  const fetchData = async () => {
      try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${process.env.REACT_APP_API_URL}overview.php`, {
            headers: {
              'Authorization': `Bearer ${token}`, // Add the Authorization header
              'Content-Type': 'application/json'  // Ensure the content type is set
            }
          });
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          const result = await response.json();
          if(result.message) {
            logout();
            return;
          }
          console.log(result);
          setData(result);
          setLoading(false);
      } catch (error) {
          console.error(error)
      }
  };

  // Call the async function
  fetchData();
}, []);

function closeallandopenFeedback() {
  setTrailopen(null);
  setFeedbackopen(true);
}



  return (
    <div className="App">
      <div className='wrapper' style={{marginBottom: 120}}>
      <header>
          <div>
            <img src={logo} className='logo' />
          </div>
          <div className='logoutbutton' onClick={() => logout()}>
            Abmelden
          </div>
      </header>

      {!loading ?
      <div>
        <div className='hero'>
          <h1>{data.region.name}</h1>
        </div>

        <div className='container containergrid3'>
          <div className='databox'>
            <p className='data-val'>{formatNumber(data.user_count)}</p>
            <p className='data-label'>Neue Familien</p>
          </div>

          <div className='databox'>
            <p className='data-val'>{formatNumber(data.trails.reduce((sum, trail) => sum + parseInt(trail.done), 0))}</p>
            <p className='data-label'>Badges gesammelt</p>
          </div>

          <div className='databox'>
            <p className='data-val'>{formatNumber(data.totalstations)}</p>
            <p className='data-label'>Stationen angesehen</p>
          </div>
        </div>

        <div className='container'>
          <Chart id="usersovertime" options={lineChartOptionsnew} series={lineChartOptionsnew.series} type="line" height="400" />
        </div>

        <div className='container'>
          <Chart options={trailpiechart.options} series={trailpiechart.series} type="donut" width="100%" />
        </div>

<div className='containertwocolumn'>
        <div className='container'>
          <Chart options={languagedatanew.options} series={languagedatanew.series} type="donut" width="100%" />
        </div>

        <div className='container'>
          <Chart options={visittimesdata.options} series={visittimesdata.series} type="bar" width="100%" height={'90%'} />
          <p style={{textAlign: 'center', marginTop: 12}}>Top Tageszeit: <b>{visittimesdata.maxindex + ":00 - " + (visittimesdata.maxindex + 1) + ":00"}</b></p>
        </div>
        </div>
      

        <h2 className='sectionheadline'>{data.trails.length == 1 ? "1 Weg" : data.trails.length + " Wege"}</h2>

        {
          data?.trails?.map((obj, index) => {

            let newstarted = parseInt(obj.done) > parseInt(obj.started) ? obj.done : obj.started;
            
          return (
          <div className='trailbox' key={index} onClick={() => setTrailopen(obj)}>
            <div className='trailboximg' style={{backgroundImage: `url(${obj.image})`}}></div>
            <div className='trailboxtext'>
              <h2>{obj.title}</h2>
              <div className='trailboxdatagrid'>
                <div className='trailboxdatabox'>
                  <p className='trailboxdata-val'>{formatNumber(newstarted)}</p>
                  <p className='trailboxdata-label'>Gestartet</p>
                </div>

                <div className='trailboxdatabox'>
                  <p className='trailboxdata-val'>{formatNumber(obj.done)}</p>
                  <p className='trailboxdata-label'>Erledigt</p>
                </div>

                <div className='trailboxdatabox'>
                  <p className='trailboxdata-val'>{newstarted == 0 ? 0 : (obj.done / newstarted * 100).toFixed(0) }<span className='val-smalltext'>%</span></p>
                  <p className='trailboxdata-label'>Success rate</p>
                </div>

                <div className='trailboxdatabox'>
                  <p className='trailboxdata-val'>{obj['rating-avg'] ? parseFloat(obj['rating-avg']).toFixed(1) : <span style={{opacity: 0.4}}>–</span>}</p>
                  <p className='trailboxdata-label'>Rating ({obj['rating-count']})</p>
                  {obj['rating-avg'] && <StarRating rating={parseFloat(obj['rating-avg'])} width={60} /> }
                </div>
              </div>

              {/*<div className='button'>Mehr Infos</div>*/}
            </div>
            {obj.live > 0 && <div className='livebadge'><img src={livedot} />Jetzt unterwegs: <b style={{marginLeft: 4}}>{obj.live}</b></div>}
            <img src={arrow} className='trailboxarrow' ></img>
          </div>
        )})
        }
<div className='feedbackheadlinesection'>
        <h2>Neuestes Feedback</h2>
        <div className='secondarybtn' onClick={() => setFeedbackopen(true)}>Alle anzeigen</div>
        </div>
          <div className='feedbackcontainer'>
          {
            data?.feedback?.map((obj, index) => (
              <FeedbackCard key={index} {...obj} slug={data.region.slug} />
            ))
          }
        </div>
      </div>
      :
      <div className='loadingcont'><img src={loadingimg} /></div>
      }
    </div>
    <TrailModal trail={trailopen} onClose={() => setTrailopen(null)} closeallandopenFeedback={() => closeallandopenFeedback()}  />
    <FeedbackModal opened={feedbackopen} onClose={() => setFeedbackopen(false)} 
     />
    </div>
  );
}

export default Dashboard;
